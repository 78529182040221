import { render, staticRenderFns } from "./ModuleList.vue?vue&type=template&id=f564b624&"
import script from "./ModuleList.vue?vue&type=script&setup=true&lang=js&"
export * from "./ModuleList.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports