<template>
    <div v-if="level" class="mt-7 text-secondary-one space-y-4">
        <Properties
            :properties="properties"
            :activeScenarioCollection="level"
            :module="module"
            :project="project">

          <div class="mb-5 i-border-b-1 border-secondary-two pb-4 -i-mt-6">
            <Accordion
                title="Content Configuration"
                :active="true"
                bg="transparent"
                classes="shadow-none"
                titleClasses="text-primary-one fs-14 fw-600"
                iconClasses="w-4 h-4 mr-1"
                buttonClasses="i-right-n-2 i-top-0"
                accordionClasses=""
                align="left"
            >
              <div class="space-y-4 fs-14 mt-4" :style="{pointerEvents: level.children.length ? 'none' : 'auto'}">
                <div class="flex items-center justify-between">
                  <span>Start date:</span>
                  <Datepicker
                      wrapper-class="high-date h-7"
                      v-model="level.start_date"
                      :input-class="[inputClass, 'w-full h-7']"
                      :calendar-class="'-left-32 __calendar-width'"
                      format="dd.MM.yyyy"
                      @input="setDate($event, 'start_date')"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <span>End date:</span>
                  <Datepicker
                      wrapper-class="high-date h-7"
                      :input-class="[inputClass, 'w-full h-7']"
                      :calendar-class="'-left-32 __calendar-width'"
                      v-model="level.end_date"
                      format="dd.MM.yyyy"
                      @input="setDate($event, 'end_date')"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <span>Duration:</span>
                  <div class="flex items-center justify-center" :class="inputClass">
                    <input
                        type="text"
                        class="bg-transparent w-7 text-center"
                        :value="totalDays"
                        @input="updateEndDate"
                    >
                    days
                  </div>

                </div>
              </div>
            </Accordion>
          </div>

        </Properties>
    </div>
</template>

<script setup>
import { watchEffect, computed } from "vue"
import Accordion from "@/elements/atom/Accordion.vue";
import Datepicker from 'vuejs-datepicker';
import { dateDefer } from '@/plugins/lodash.js'
import { format, add } from 'date-fns'
import axios from 'axios'
import Properties from '../Properties.vue'

const props = defineProps({
    level: Object,
    systemDates: Object,
    strategy: Object,
    project: Object,
    properties: Object,
    module: Object,
    setSystemData: Function
})

const inputClass = 'high-date bg-transparent fs-14 fw-400 border border-secondary-four rounded-sm text-center'

const totalDays = computed(() => {
    const { start_date, end_date} = props.level
    let day = dateDefer(start_date, end_date)
    if(!day) return ''
    return day
})

watchEffect(() => {
    if(!props.level) return
    const { start_date:levelStartDate, end_date:levelEndDate} = props.level
    const { item_start_date, item_end_date } = props.systemDates
    if(!levelStartDate) props.level.start_date = item_start_date
    if(!levelEndDate) props.level.end_date = item_end_date
})

const updateEndDate = (e) => {
    if(e.target.value) {
        const nextDate = add(new Date(props.level.start_date), {days: e.target.value})
        props.level.end_date = format(nextDate, 'yyyy-MM-dd')
        //onDateChange(item)
        axios.post(`projects/scenarios/${props.strategy.scenario_id}/update/${props.level.id}`, props.level)
        props.setSystemData(props.strategy)
    }
}

const setDate = (e, key) => {
    props.level[key] = format(e, 'yyyy-MM-dd')
    axios.post(`projects/scenarios/${props.strategy.scenario_id}/update/${props.level.id}`, props.level)
    props.setSystemData(props.strategy)
}
</script>
<style>
.__calendar-width{
  width: 270px !important;
}
</style>

<style scoped>
.high-date{
    width: 149px !important;
    height: 30px;
}
</style>
