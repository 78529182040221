<template>
    <div class="flex items-center gap-3">
        <div v-if="strategy.settings?.colors" class="border border-primary-four text-secondary-one w-6 h-6 grid place-content-center rounded relative" v-click-outside="() => { showColorPicker = false }">
            <button @click="showColorPicker = !showColorPicker" class="relative">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_23923_170716" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="8">
                        <rect width="12" height="8" fill="#C4C4C4"/>
                    </mask>
                    <g mask="url(#mask0_23923_170716)">
                        <rect x="6.19336" width="5.80645" height="4" fill="#19A77C"/>        
                        <rect width="5.80645" height="3.5" transform="matrix(1 0 0 -1 6.19336 8)" fill="#0085FF"/>
                        <rect width="5.80645" height="4" transform="matrix(-1 0 0 1 5.80664 0)" fill="#D6D6D6"/>
                        <rect x="5.80664" y="8" width="5.80645" height="3.5" transform="rotate(-180 5.80664 8)" fill="#FE5656"/>
                    </g>
                </svg>
            </button>
            <div v-show="showColorPicker" class="colorPickers absolute left-0 top-full mt-1 flex flex-col gap-1 z-30 bg-white p-1 shadow">
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[0]}" >
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[0]" @input="changeColor" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[1]}" >
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[1]"  @input="changeColor" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: strategy.settings.colors[2]}">
                    <input type="color" class="absolute opacity-0" v-model="strategy.settings.colors[2]" @input="changeColor" >
                </label>
            </div>
        </div>
        <!-- <div
            v-for="(item,i) in items"
            :key="i"
            class="border border-primary-four text-secondary-one w-6 h-6 grid place-content-center rounded"
            @click="$emit(item.emit)"
        >
            <component
                class="w-3 h-3"
                :is="item.component"
            />
        </div> -->
        
    </div>
</template>

<script setup>
import { ref } from 'vue'
import useHighLevelData from '@/views/screens/roadmap/strategicRoadmap/highLevelData/useHighLevelData.js'

const showColorPicker = ref(false)
const props = defineProps({
    items: {
        type: Array,
        default: () => [
            {
                emit: 'onClickColor',
                component: 'ColorIcon'
            },{
                emit: 'onClickColor',
                component: 'ColorIcon'
            },
        ]
    }
})

const { strategy, updateStrategy } = useHighLevelData(true)
let timeoutId = null
const changeColor = () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
        updateStrategy(strategy.value)
    }, 1000)
}
</script>