<template>
<div class="flex gap-3 mb-4 items-center">
    <router-link
        v-for="(item, i) in items"
        :key="i"
        class="border border-primary-four text-secondary-one w-6 h-6 grid place-content-center rounded hover:bg-primary-four hover:text-green-100 bg-white hover:border-white"
        :to="{
            name: item.href,
            prams: {id: $route.params.id}
        }"
    >
        <component :is="item.icon" class="w-3 h-3"></component>
    </router-link>
</div>
</template>

<script setup>
    import FlagIcon from '@/components/roadmap/strategicRoadmap/components/icons/FlagIcon.vue'
    import AlignIcon from '@/components/roadmap/strategicRoadmap/components/icons/AlignIcon.vue'
    import ChartIcon from '@/components/roadmap/strategicRoadmap/components/icons/ChartIcon.vue'

    const items = [
        {href: 'high-level-roadmap', icon: FlagIcon},
        {href: 'planning', icon: ChartIcon},
        {href: 'roadmap.highlevel.data', icon: AlignIcon},
    ]
</script>

<style scoped>
.router-link-exact-active, .router-link-active{
    color: #fff;
    background-color: #2B80AD;
    border-color: #fff;
}
</style>